html, body {
  background-color: #FBFBFD;
  font-family: 'Roboto', sans-serif;
}

.stage {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  overflow: scroll;
}

a {
  color: #1665D8;
  text-decoration: none;
}

