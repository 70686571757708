.projects {
    padding: 30px;
    overflow: auto;
}

.projecttable table th {
    text-align: right;
    align-items: right;
    background-color: "red";
}

.projectheader {
    display: flex;
    flex-direction: row;
    align-items: center;
}