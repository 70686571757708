
.navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background-color: #FFFFFF;
    border-bottom: 1px solid #F0F0F1;
    height: 70px;
    padding: 0 30px 0 30px;
    color: #6B6C6F;
    font-size: 14px;
    font-weight: 500;
    position: sticky;
  }

  .navigation a {
      font-size: 14px;
      color: #6B6C6F;
      text-decoration: none;
  }

  .navigation a:hover {
      color: #1665D8;
  }
  
  .logo h2 {
    width: 130px;
    color: #3E3F42;
    font-size: 1rem;
    display: flex;
    align-content: center; 
  }
  
  .navbarspacer {
    background-color: #EAEDF3;
    width: 1px;
    height: 38px;
  }

  .menuitems {
      margin: 0 20px 0 20px;
  }

  .menuitems a {
      margin: 0 20px 0 20px;
  }

  .menuitems a.active {
    color: #1665D8;
  }

  .searchbar {
      flex: 1;
  }

  .settings {
      margin-left: 30px;
  }